$fonts: (
  skf-sans-bold: 'SKF-sans-bold',
  skf-sans-bold-italic: 'SKF-sans-bolditalic',
  skf-sans-italic: 'SKF-sans-italic',
  skf-sans-light: 'SKF-sans-light',
  skf-sans-light-italic: 'SKF-sans-lightitalic',
  skf-sans-medium: 'SKF-sans-medium',
  skf-sans-medium-italic: 'SKF-sans-mediumitalic',
  skf-sans-regular: 'SKF-sans-regular',
);

/* Bootstrap overrides
======================================================*/

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1440px,
  xxl: 1600px,
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1260px,
  xxl: 1438px,
);

$accordion-icon-color: $night;
$accordion-icon-active-color: $night;

@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
