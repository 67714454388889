// Button padding
.btn-small {
  @extend .body-75;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: calc-rem(4);
  gap: calc-rem(2);
  .material-icons,
  .material-icons-outlined {
    font-size: calc-rem(16);
    margin-right: 0.25rem;
  }
}

.btn-medium {
  @extend .body-100;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: calc-rem(8);
  gap: calc-rem(4);
  .material-icons,
  .material-icons-outlined {
    font-size: calc-rem(18);
    margin-right: 0.25rem;
  }
}

.btn-large {
  @extend .body-100;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: calc-rem(8) calc-rem(16);
  gap: calc-rem(4);
  .material-icons,
  .material-icons-outlined {
    font-size: calc-rem(18);
    margin-right: 0.25rem;
  }
}

// Button styles
.btn-primary {
  @include btn-generate($white, $grass, none);
}

.btn-secondary {
  @include btn-generate($white, $primary-600, none);
}

.btn-neutral {
  @include btn-generate($white, $clay, none);
}

.btn-danger {
  @include btn-generate($white, $danger-600, none);
}

.btn-tertiary {
  @include btn-generate($primary-600, $white, 1px solid $primary-600);
  &:disabled {
    border: $gray-500 1px solid;
    color: $gray-500;
    pointer-events: none;
  }
}

.btn-outlined {
  text-transform: uppercase;
  color: $primary-600;
  background: none;
  border: none;
  &:hover:enabled {
    color: $primary-700;
    cursor: pointer;
    text-decoration: underline;
  }
  &:disabled {
    color: $gray-500;
    pointer-events: none;
  }
}

// Component buttons
.btn-primary-lg {
  @extend .btn-primary;
  @extend .btn-large;
}
.btn-primary-md {
  @extend .btn-primary;
  @extend .btn-medium;
}
.btn-primary-sm {
  @extend .btn-primary;
  @extend .btn-small;
}

.btn-secondary-lg {
  @extend .btn-secondary;
  @extend .btn-large;
}
.btn-secondary-md {
  @extend .btn-secondary;
  @extend .btn-medium;
}
.btn-secondary-sm {
  @extend .btn-secondary;
  @extend .btn-small;
}

.btn-neutral-lg {
  @extend .btn-neutral;
  @extend .btn-large;
}
.btn-neutral-md {
  @extend .btn-neutral;
  @extend .btn-medium;
}
.btn-neutral-sm {
  @extend .btn-neutral;
  @extend .btn-small;
}

.btn-danger-lg {
  @extend .btn-danger;
  @extend .btn-large;
}
.btn-danger-md {
  @extend .btn-danger;
  @extend .btn-medium;
}
.btn-danger-sm {
  @extend .btn-danger;
  @extend .btn-small;
}

.btn-tertiary-lg {
  @extend .btn-tertiary;
  @extend .btn-large;
}
.btn-tertiary-md {
  @extend .btn-tertiary;
  @extend .btn-medium;
}
.btn-tertiary-sm {
  @extend .btn-tertiary;
  @extend .btn-small;
}

.btn-outlined-lg {
  @extend .btn-outlined;
  @extend .btn-large;
}
.btn-outlined-md {
  @extend .btn-outlined;
  @extend .btn-medium;
}
.btn-outlined-sm {
  @extend .btn-outlined;
  @extend .btn-small;
}

.add-to-cart-md {
  min-width: calc-rem(250);
  @extend .btn-secondary-lg;
  &.add-feedback {
    background-color: $primary-400 !important;
    color: $white !important;
  }
}
.add-to-cart-sm {
  min-width: calc-rem(120);
  padding: 8px 16px;
  @extend .btn-secondary-sm;
  &.add-feedback {
    background-color: $primary-400 !important;
    color: $white !important;
  }
}

.add-to-cart-partial-md {
  min-width: clac-rem(131);
  @extend .btn-tertiary-md;
  &.add-feedback {
    border: 1px solid $primary-400 !important;
    color: $primary-400 !important;
  }
}
.add-to-cart-partial-sm {
  min-width: calc-rem(120);
  padding: 8px 16px;
  @extend .btn-tertiary-sm;
  &.add-feedback {
    border: 1px solid $primary-400 !important;
    color: $primary-400 !important;
  }
}
.add-to-inquiry-md {
  @extend .btn-neutral-lg;

  &.add-feedback {
    background-color: $gray-600 !important;
    color: $white !important;
  }
}

.add-to-inquiry-sm {
  min-width: calc-rem(120);
  padding: 8px 16px;
  @extend .btn-neutral-sm;
  &.add-feedback {
    background-color: $gray-600 !important;
    color: $white !important;
  }
}
