/* SKF Fonts */
@font-face {
  font-family: 'SKF-sans-bold';
  src: url(/assets/fonts/SKFSans-Bold.woff2) format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'SKF-sans-bolditalic';
  src: url(/assets/fonts/SKFSans-BoldItalic.woff2) format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'SKF-sans-italic';
  src: url(/assets/fonts/SKFSans-Italic.woff2) format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'SKF-sans-light';
  src: url(/assets/fonts/SKFSans-Light.woff2) format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'SKF-sans-lightitalic';
  src: url(/assets/fonts/SKFSans-LightItalic.woff2) format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'SKF-sans-medium';
  src: url(/assets/fonts/SKFSans-Medium.woff2) format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'SKF-sans-mediumitalic';
  src: url(/assets/fonts/SKFSans-MediumItalic.woff2) format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'SKF-sans-regular';
  src: url(/assets/fonts/SKFSans-Regular.woff2) format('woff2');
  font-display: swap;
}
