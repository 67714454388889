@import 'node_modules/bootstrap/scss/mixins';
@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';

@mixin transform($transforms) {
  -webkit-transform: $transforms;
  -moz-transform: $transforms;
  -ms-transform: $transforms;
  transform: $transforms;
}

@mixin vertical-align($position: relative, $top: 50%) {
  position: $position;
  top: $top;
}

// TranslateY
@mixin translateY($translateY) {
  @include transform(translateY($translateY));
}

@mixin icon-size($height: 20px, $width: 20px) {
  height: $height;
  width: $width;
}

@mixin btn-generate($text-color, $btn-color, $border) {
  text-transform: uppercase;
  color: $text-color;
  background-color: $btn-color;
  border: $border;
  &:hover:enabled {
    @if ($border == 'none') {
      background-color: darken($btn-color, 5%);
    }
    cursor: pointer;
    text-decoration: none !important;
  }
  &:disabled {
    @if ($border == 'none') {
      background-color: $gray-500;
      color: $text-black;
      pointer-events: none;
      opacity: 60%;
    }
  }
}

@mixin input-border($color, $width) {
  border: $width solid $color;
}

/* Typography
=========================================================*/

@mixin font-family($family) {
  font-family: get-font-family($family), $font-family-base;
}

@mixin fontSize-lineHeight($fontSize: 20px, $lineHeight: 20px) {
  font-size: calc-rem($fontSize);
  line-height: $lineHeight;
}
