h3 {
  @extend .heading-500;
}

h4 {
  @extend .heading-400;
}

h5 {
  @extend .heading-300;
}

h6 {
  @extend .heading-200;
}

/* NEW headings  */

.heading-500 {
  @include font-family(skf-sans-regular);
  color: $text-black;
  font-size: calc-rem(46);
}

.heading-400 {
  @include font-family(skf-sans-medium);
  color: $text-black;
  font-size: calc-rem(32);
}

.heading-300 {
  @include font-family(skf-sans-bold);
  color: $text-black;
  font-size: calc-rem(24);
}

.heading-200 {
  @include font-family(skf-sans-bold);
  color: $text-black;
  font-size: calc-rem(20);
}

.heading-150 {
  @include font-family(skf-sans-bold);
  color: $text-black;
  font-size: calc-rem(18);
}
