/**
 * LABELS
 *
 * Custom labels are defined here.
*/
.omr-alert-label {
    display: flex;
    align-items: start;
    margin-top: 10px;

    .material-icons {
        color: $alert-500 !important;
        font-size: 20px;
        transform: translateY(4%);
    }
}