/**
 * Inputs
 *
 * Custom Inputs are defined here.
*/

// Borders
.border-error,
.border-error:focus {
  border: 1px solid $danger-600;
  border-color: $danger-600 !important;
}

.border-info,
.border-info:focus {
  border: 1px solid $minor-500;
  border-color: $minor-500 !important;
}

.border-warning,
.border-warning:focus {
  border: 1px solid $warning-500;
  border-color: $warning-500 !important;
}

.form-control:disabled {
  background-color: #e2e4e7;
  opacity: 1;
  border: 1px solid #c7cad0 !important;
}

.form-message {
  margin-top: 8px;
}

// Error messages
.message-error {
  @extend .body-75;
  @extend .form-message;
  color: $danger-600 !important;
}

.message-error-light {
  @extend .body-100;
  @extend .form-message;
  color: $danger-600 !important;
}

.message-advice {
  @extend .body-75;
  @extend .form-message;
  color: $purple-600 !important;
}

.message-info {
  @extend .body-75;
  @extend .form-message;
  .availability-link {
    cursor: pointer;
  }
  u {
    color: $minor-500 !important;
    text-decoration: none;
    cursor: pointer;
  }
}

.message-success {
  @extend .body-75;
  @extend .form-message;
  color: $grass !important;
}

.message-warning {
  @extend .body-75;
  @extend .form-message;
  color: $warning-500 !important;

  .material-icons {
    color: $warning-500;
    font-size: 20px;
    @include translateY(28%);
  }
}
