.customer-green {
    color: $forest;
}

.customer-blue {
    color: $primary-700;
}

.customer-grey {
    color: $ocean;
}

.customer-brown {
    color: $earth;
}

.customer-purple {
    color: $purple;
}

.customer-red {
    color: $danger-hover;
}

.customer-stone {
    color: $stone;
}

.customer-cloud {
    color: $cloud;
}