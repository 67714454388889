p {
  margin-bottom: 0;
}

/* NEW SKF FONTS  */

.body-200 {
  @include font-family(skf-sans-medium);
  color: $text-black;
  font-size: calc-rem(18);
}

.body-100 {
  @include font-family(skf-sans-regular);
  color: $text-black;
  font-size: calc-rem(16);
}

.body-75 {
  @include font-family(skf-sans-medium);
  color: $text-black;
  font-size: calc-rem(14);
}

.detail-50 {
  @include font-family(skf-sans-regular);
  color: $text-black;
  font-size: calc-rem(12);
}
