@media print {
  table,
  img,
  svg {
    -moz-column-break-inside: avoid;
    break-inside: avoid;
  }

  @-moz-document url-prefix() {
    table {
      -moz-column-break-after: avoid;
      break-after: avoid;
      -moz-column-break-before: always;
      break-before: always;
    }
  }

  .skip-print {
    display: none !important;
  }

  .print-only-header {
    display: flex;
    justify-content: space-between;
    margin: 2% 0;
  }
  
  .img-style {
    width: 113px;
    height: 100%;
    margin-right: 0;
    filter: invert(26%) sepia(100%) saturate(7305%) hue-rotate(216deg) brightness(96%) contrast(88%);
  }

  // remove header and footer of printed page
  @page {
    margin-top: 0;
    margin-bottom: 0;
    size: A4;
  }
}
