/**
 * COLORS
 *
 * This file should contain variables that corresponds to the SKF color palette.
 *
 * NOTE! This file should never be imported by itself since it's being imported
 * in the _helpers-partial.
*/

// Identity colors 
$text-black: #273342;
$white: #FFFFFF;
$primary-800: #09398B;
$primary-700: #0C4AB6;
$primary-600: #0F58D6; 
$primary-500: #6292E4;
$primary-400: #9FBCEE;
$primary-300: #CFDEF7;
$primary-200: #E7EEFE;

// Gray colors
$gray-25: #F6F6F6;
$gray-50: #F6F6F6; 
$gray-100: #EBECEE;
$gray-200: #E2E4E7;
$gray-300: #D5D8DC;
$gray-400: #C7CAD0;
$gray-500: #AAAFB8;
$gray-600: #808895;
$gray-700: #676F7C;

// Background colors
$night: #264357;
$ocean: #7F9AA5;
$sky: #D7F1F4;
$clay: #485A64;
$storm: #99A5AA;
$cloud: #EBF0F0;
$stone: #5F5F64;
$iron: #9EA0A2;
$steel: #DCE0E1;
$forest: #426460;
$pine: #94ABA6;
$mist: #E6F3EC;
$earth: #786251;
$leather: #B2A799;
$sand: #ECECE0;

// Functional colors
$good-600: #048942;
$minor-500: #4F84E0;
$alert-500: #FFCA15;
$warning-500: #FF8004;
$danger-600: #CC0000;

// Accent colors
$grass: #46962B;
$green: #88C008;
$purple: #781E93;
$yellow: #FFD615;
$orange: #FF8004;

// Interaction state colors
$hover: #D7F1F4;
$pressed: #C3EAEE;
$selected: #F6F6F6;
$danger-hover: #AE0000;

// SKF For Me custom colors
$medium-blue: #2B5876;
$danger-300: #FF453A;